import 'yup-phone';
import '../../configs/yup-pt-br';

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateCNPJ, validateCPF } from 'validations-br';

import UI from '#front-credpago';

import { ReactComponent as IconPhone } from '../../images/icons/0800.svg';
import { ReactComponent as IconZap } from '../../images/icons/zap.svg';
import { apiIBGE, apiImobiliaria } from '../../services/api';
import { formatCNPJ, formatCPF, formatPhone } from '../../utils/format';
import useDebounce from '../../utils/useDebounce';
import { cadastreSchema } from '../../validations/cadastre';
import { handleBlankSpaces, handleNumbers } from '../../validations/fields';
import * as S from './styles';

const TenantOrOwnerForm = () => {
  return (
    <div
      style={{
        display: 'flex',
        rowGap: '24px',
        flexDirection: 'column',
        margin: '24px 73px 24px 0px',
      }}
    >
      <UI.Title
        level={6}
        fontWeight={700}
        label={'Esta página é dedicada exclusivamente ao cadastro de novos parceiros.'}
        style={{ color: 'rgba(32, 196, 244, 1)' }}
      />

      <UI.Title
        level={6}
        fontWeight={700}
        label={
          'Se tiver dúvidas, entre em contato com sua imobiliária. Ou, se preferir, contate nosso time de atendimento:'
        }
      />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <div style={{ width: '40px' }}>
            <IconZap />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}
          >
            <span
              style={{
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '26px',
                fontFamily: 'Uni Neue',
                color: '#003863',
              }}
            >
              Atendimento digital
            </span>
            <span
              style={{
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '26px',
                fontFamily: 'Uni Neue',
                color: '#003863',
              }}
            >
              (41) 99148-8056
            </span>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <div style={{ width: '48px' }}>
            <IconPhone />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}
          >
            <span
              style={{
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '26px',
                fontFamily: 'Uni Neue',
                color: '#003863',
              }}
            >
              Atendimento
            </span>
            <span
              style={{
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '26px',
                fontFamily: 'Uni Neue',
                color: '#003863',
              }}
            >
              0800 001 6002
            </span>
          </div>
        </div>
      </div>

      <p
        style={{
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '18px',
          textAlign: 'center',
          margin: '0px',
          padding: '0px 56px',
        }}
      >
        O nosso atendimento é de Segunda a Sexta-feira das 9h00 às 19h00 e aos Sábados das 9h00 às 13h00 (exceto
        feriados nacionais)
      </p>
    </div>
  );
};

const Form = () => {
  const [userType, setUserType] = useState(0);
  const [messages, setMessages] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [cpf, setCPF] = useState('');
  const [cnpj, setCNPJ] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [currentState, setCurrentState] = useState('');
  const [currentCity, setCurrentCity] = useState('');

  const { register, handleSubmit, errors, setValue, getValues, setError, clearErrors } = useForm({
    resolver: yupResolver(cadastreSchema),
    reValidateMode: 'onSubmit',
    defaultValues: {
      corretor_autonomo: userType,
    },
  });

  const debouncedCPF = useDebounce(cpf, 3000);
  const debouncedCNPJ = useDebounce(cnpj, 3000);

  const onSubmit = useCallback(async (data) => {
    try {
      setMessages([]);

      if (Object.keys(errors).length > 0) {
        let message = '';
        for (const key in errors) {
          message += errors[key].message.replace('Entre em contato com a CredPago.', ' ');
        }
        setMessages([
          {
            type: 'error',
            content: message,
          },
        ]);
        return;
      }

      if (data.corretor_autonomo === 0) {
        delete data.cpf;
      } else if (data.corretor_autonomo === 1) {
        delete data.cnpj;
      }

      const response = await apiImobiliaria.post('imobiliaria', {
        city: data.cidade,
        cnpj: data.cnpj,
        cpf: data.cpf,
        creci: data.creci,
        email: data.email,
        ibgeCityId: data.id_cidade_ibge,
        independentBroker: data.corretor_autonomo,
        name: data.nome,
        password: data.senha,
        passwordConfirmation: data.senha_confirmacao,
        phone: data.telefone,
        portfolio: data.carteira,
        uf: data.uf,
      });

      if (!response.data) {
        setMessages([
          {
            type: 'error',
            content: 'Ocorreu um erro ao realizar seu cadastro. Tente novamente',
          },
        ]);

        return;
      }

      setMessages([
        {
          type: 'success',
          content: 'Cadastro realizado! Verifique seu e-mail para mais informações. Você será redirecionado...',
        },
      ]);

      setTimeout(() => {
        window.location.replace(`${process.env.GATSBY_CP_HOME_PLATAFORMA}/login.php`);
      }, 5000);
    } catch (error) {
      console.log(error);
      setMessages([
        {
          type: 'error',
          content:
            error?.response?.data?.error?.description ?? 'Ocorreu um erro ao realizar seu cadastro. Tente novamente',
        },
      ]);
    }
  }, []);

  const checkCPF = useCallback(async (data) => {
    if (!validateCPF(data)) {
      setError('cpf', {
        message: 'CPF não é válido.',
      });
      return;
    }

    clearErrors('cpf');
  }, []);

  const checkCNPJ = useCallback(async (data) => {
    if (!validateCNPJ(data)) {
      setError('cnpj', {
        message: 'CNPJ não é válido.',
      });
      return;
    }

    clearErrors('cnpj');
  }, []);

  const loadStates = useCallback(async () => {
    try {
      const response = await apiIBGE.get('estados');

      if (response.data) {
        const formattedStates = [];

        response.data.map((state) =>
          formattedStates.push({
            id: state.sigla,
            value: state.nome,
          }),
        );

        const sorted = formattedStates.sort((a, b) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        });

        sorted.unshift({
          id: '',
          value: 'Selecione',
        });

        setStates(sorted);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const loadCities = useCallback(async () => {
    try {
      const response = await apiIBGE.get(`estados/${currentState}/distritos`);

      if (response.data) {
        const formattedCities = [];

        response.data.map((city) =>
          formattedCities.push({
            id: city.municipio.id,
            value: city.municipio.nome,
          }),
        );

        const sorted = formattedCities.sort((a, b) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        });

        const filtered = sorted.filter(
          (item, index) => sorted.findIndex((i) => JSON.stringify(i) === JSON.stringify(item)) === index,
        );

        filtered.unshift({
          id: '',
          value: 'Selecione',
        });

        setCities(filtered);
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentState]);

  const loadCarteira = useCallback(() => {
    return [
      {
        id: '0',
        value: 'Carteira em construção',
      },
      {
        id: '1',
        value: 'Entre 1 e 10 imóveis',
      },
      {
        id: '10',
        value: 'Entre 10 e 50 imóveis',
      },
      {
        id: '50',
        value: 'Entre 50 e 100 imóveis',
      },
      {
        id: '100',
        value: 'Mais de 100 imóveis',
      },
    ];
  });

  useEffect(() => {
    loadCarteira();
  }, [loadStates]);

  useEffect(() => {
    loadStates();
  }, [loadStates]);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }

    loadCities();
  }, [loadCities, currentState]);

  useEffect(() => {
    register('corretor_autonomo');

    register('cpf');
    register('cnpj');
    register('email');
    register('uf');

    register('id_cidade_ibge');

    register('cidade');
  }, []);

  useEffect(() => {
    const loadValidateCPF = async () => {
      if (debouncedCPF) {
        checkCPF(debouncedCPF.replace(/[^0-9]/g, ''));
      }
    };

    loadValidateCPF();
  }, [debouncedCPF]);

  useEffect(() => {
    const loadValidateCNPJ = async () => {
      if (debouncedCNPJ) {
        checkCNPJ(debouncedCNPJ.replace(/[^0-9]/g, ''));
      }
    };

    loadValidateCNPJ();
  }, [debouncedCNPJ]);

  return (
    <div>
      <UI.Row gutter={1}>
        <UI.Col responsive={{ md: 12, xs: 12, sm: 12 }}>
          <S.CustomForm onSubmit={handleSubmit(onSubmit)}>
            <UI.Row>
              <UI.Col responsive={{ md: 12, xs: 12, sm: 12 }}>
                <UI.Radio
                  name="corretor_autonomo"
                  onChange={(e) => {
                    setValue('corretor_autonomo', e.target.value);
                    setUserType(e.target.value);
                    setValue('cpf', '');
                    setValue('cnpj', '');
                    clearErrors('cnpj');
                    clearErrors('cpf');
                  }}
                  value={userType}
                  radios={[
                    {
                      label: 'Imobiliária',
                      value: 0,
                    },
                    {
                      label: 'Corretor Autônomo',
                      value: 1,
                    },
                    {
                      label: 'Inquilino ou Proprietário',
                      value: 3,
                    },
                  ]}
                />
              </UI.Col>
            </UI.Row>

            {userType === 3 ? (
              <TenantOrOwnerForm />
            ) : (
              <>
                <UI.Row>
                  <UI.Col responsive={{ md: 12, xs: 12, sm: 12 }}>
                    <UI.InputLabel
                      id="nome"
                      label="Nome"
                      name="nome"
                      error={{
                        hasError: !!errors.nome,
                        message: errors?.nome?.message,
                      }}
                      ref={register}
                      required
                      onBlur={(e) => setValue('nome', handleBlankSpaces(e.target.value))}
                    />
                  </UI.Col>
                </UI.Row>
                <UI.Row>
                  <UI.Col responsive={{ md: 7, xs: 12, sm: 12 }}>
                    <UI.InputLabel
                      htmlType="email"
                      id="email"
                      label="E-mail"
                      name="email"
                      error={{
                        hasError: !!errors.email,
                        message: errors?.email?.message,
                      }}
                      onChange={(e) => {
                        setEmail(e);
                      }}
                      ref={register}
                      required
                      onBlur={(e) => setValue('email', handleBlankSpaces(e.target.value, true))}
                    />
                  </UI.Col>
                  <UI.Col responsive={{ md: 5, xs: 12, sm: 12 }}>
                    <UI.InputLabel
                      htmlType="tel"
                      label="Telefone"
                      name="telefone"
                      error={{
                        hasError: !!errors.telefone,
                        message: errors?.telefone?.message,
                      }}
                      value={phone}
                      onChange={(e) => {
                        const cleanE = e.replace(/[^0-9]/g, '');
                        if (cleanE.length <= 11) {
                          setValue('telefone', e);
                          setPhone(e);
                        }
                      }}
                      onBlur={() => {
                        const cleanField = handleBlankSpaces(phone, true);
                        if (cleanField.length <= 15) {
                          const formatedPhone = formatPhone(phone);
                          setValue('telefone', handleBlankSpaces(formatedPhone, true));
                          setPhone((prevState) => formatPhone(handleBlankSpaces(prevState, true)));
                        }
                      }}
                      ref={register}
                      required
                    />
                  </UI.Col>
                </UI.Row>
                <UI.Row>
                  <UI.Col responsive={{ md: 6, xs: 12, sm: 12 }}>
                    {userType === 1 ? (
                      <UI.InputLabel
                        id="cpf"
                        label="CPF"
                        name="cpf"
                        error={{
                          hasError: !!errors.cpf,
                          message: errors?.cpf?.message,
                        }}
                        ref={register}
                        required
                        onChange={(text) => {
                          const cleanCPF = handleNumbers(text);

                          if (cleanCPF.length === 11) {
                            setCPF(text);
                            setValue('cpf', formatCPF(text));
                          } else {
                            setValue('cpf', text);
                          }
                        }}
                        onBlur={(e) => {
                          const cleanCPF = handleNumbers(e.target.value);
                          if (cleanCPF.length === 11) {
                            const formatted = formatCPF(cleanCPF);
                            setValue('cpf', formatted);
                          }
                        }}
                      />
                    ) : (
                      <UI.InputLabel
                        id="cnpj"
                        label="CNPJ"
                        name="cnpj"
                        error={{
                          hasError: !!errors.cnpj,
                          message: errors?.cnpj?.message,
                        }}
                        onChange={(text) => {
                          const cleanCNPJ = handleNumbers(text);
                          if (cleanCNPJ.length === 14) {
                            setCNPJ(text);
                          }
                        }}
                        ref={register}
                        required
                        onBlur={(e) => {
                          const cleanCNPJ = handleNumbers(e.target.value);
                          if (cleanCNPJ.length === 14) {
                            setValue('cnpj', formatCNPJ(cleanCNPJ));
                          }
                        }}
                      />
                    )}
                  </UI.Col>
                  <UI.Col responsive={{ md: 6, xs: 12, sm: 12 }}>
                    <UI.InputLabel
                      id="creci"
                      label="Número do CRECI"
                      name="creci"
                      error={{
                        hasError: !!errors.creci,
                        message: errors?.creci?.message,
                      }}
                      ref={register}
                      required
                    />
                  </UI.Col>
                </UI.Row>
                <UI.Row>
                  <UI.Col responsive={{ md: 12, xs: 12, sm: 12 }}>
                    <UI.SelectInput
                      id="carteira"
                      label="Tamanho da carteira de imóveis"
                      name="carteira"
                      error={{
                        hasError: !!errors.carteira,
                        message: errors?.carteira?.message,
                      }}
                      ref={register}
                      options={loadCarteira()}
                      required
                    />
                  </UI.Col>
                </UI.Row>
                <UI.Row>
                  <UI.Col responsive={{ md: 6, xs: 12, sm: 12 }}>
                    <UI.SelectInput
                      id="uf"
                      label="Estado"
                      name="uf"
                      options={states}
                      value={currentState || getValues('uf')}
                      error={{
                        hasError: !!errors.uf,
                        message: errors?.uf?.message,
                      }}
                      onChange={(e) => {
                        setCurrentState(e);
                        setValue('uf', e);
                      }}
                      disabled={states.length === 0}
                      onBlur={(e) => setValue('uf', handleBlankSpaces(e.target.value))}
                    />
                  </UI.Col>
                  <UI.Col responsive={{ md: 6, xs: 12, sm: 12 }}>
                    <UI.SelectInput
                      id="id_cidade_ibge"
                      label="Cidade"
                      name="id_cidade_ibge"
                      options={cities}
                      value={currentCity || getValues('id_cidade_ibge')}
                      error={{
                        hasError: !!errors.id_cidade_ibge,
                        message: errors?.id_cidade_ibge?.message,
                      }}
                      onChange={(e) => {
                        setCurrentCity(e);
                        setValue('id_cidade_ibge', e);

                        const cidade = cities.find((city) => city.id === parseInt(e));

                        setValue('cidade', cidade.value);
                      }}
                      disabled={cities.length === 0}
                    />
                  </UI.Col>
                </UI.Row>
                <UI.Row>
                  <UI.Col responsive={{ md: 6, xs: 12, sm: 12 }}>
                    <UI.InputLabel
                      htmlType="password"
                      autoComplete="new-password"
                      id="senha"
                      label="Senha"
                      name="senha"
                      error={{
                        hasError: !!errors.senha,
                        message: errors?.senha?.message,
                      }}
                      ref={register}
                      required
                    />
                  </UI.Col>
                  <UI.Col responsive={{ md: 6, xs: 12, sm: 12 }}>
                    <UI.InputLabel
                      htmlType="password"
                      autoComplete="new-password"
                      id="senha_confirmacao"
                      label="Confirme a senha"
                      name="senha_confirmacao"
                      error={{
                        hasError: !!errors.senha_confirmacao,
                        message: errors?.senha_confirmacao?.message,
                      }}
                      ref={register}
                      required
                    />
                  </UI.Col>
                </UI.Row>
                <UI.Row>
                  <UI.Col className="button-submit-container" responsive={{ md: 12, xs: 12, sm: 12 }}>
                    <UI.Button size="large" htmlType="submit">
                      Cadastrar
                    </UI.Button>
                  </UI.Col>
                </UI.Row>
              </>
            )}
          </S.CustomForm>
        </UI.Col>
      </UI.Row>

      {messages.length > 0 &&
        messages.map((message, index) => (
          <UI.Row key={index}>
            <UI.Col responsive={{ xs: 12, sm: 12, md: 12 }}>
              <S.AlertWrapper>
                <UI.Alert message={message.content} type={message.type} />
              </S.AlertWrapper>
            </UI.Col>
          </UI.Row>
        ))}
    </div>
  );
};

export default Form;
